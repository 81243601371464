import React, { useEffect, useState } from "react";
import axios from "axios";
import "./originDestination.css";

function OriginDestination() {
  const [data, setData] = useState([]);
  const [pivotData, setPivotData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [dateFrom, setDateFrom] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dateTo, setDateTo] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const payload = {
        lim: pageSize,
        sd: dateFrom,
        ed: dateTo,
        page: currentPage,
      };

      const response = await axios.post(
        "http://192.168.60.100:4100/PDTNHB/lrt/view",
        payload
      );

      const responseData = response.data || {};
      const fetchedData = responseData.data || [];
      const totalPages = responseData.infromation?.TotalPages || 1;

      setData(fetchedData);
      setTotalPages(totalPages);

      // Transform data into pivot
      const pivot = {};
      fetchedData.forEach((item) => {
        const inTerminal = item.station_code_gate_in_var || "-";
        const outTerminal = item.station_code_var || "-";

        if (!pivot[inTerminal]) pivot[inTerminal] = {};
        if (!pivot[inTerminal][outTerminal])
          pivot[inTerminal][outTerminal] = 0;

        pivot[inTerminal][outTerminal] += 1; // Tambahkan 1 untuk setiap pasangan terminal
      });

      setPivotData(pivot);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, dateFrom, dateTo]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const renderTable = () => {
    // Extract unique terminal_in and terminal_out values
    const terminalsIn = Object.keys(pivotData);
    const terminalsOut = [
      ...new Set(
        terminalsIn.flatMap((inTerminal) =>
          Object.keys(pivotData[inTerminal] || {})
        )
      ),
    ];

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Terminal In/Out</th>
            {terminalsOut.map((terminal) => (
              <th key={terminal}>{terminal}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {terminalsIn.map((inTerminal) => (
            <tr key={inTerminal}>
              <td>{inTerminal}</td>
              {terminalsOut.map((outTerminal) => (
                <td key={outTerminal}>
                  {pivotData[inTerminal]?.[outTerminal] || 0}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <div className="filters-container">
        <div className="date-filters">
          <label htmlFor="dateFrom" style={{ color: "white" }}>
            Dari:
          </label>
          <input
            id="dateFrom"
            type="date"
            value={dateFrom}
            onChange={(e) => {
              setDateFrom(e.target.value);
              setCurrentPage(1);
            }}
            style={{ marginRight: "2px" }}
          />

          <label htmlFor="dateTo" style={{ color: "white" }}>
            Sampai:
          </label>
          <input
            id="dateTo"
            type="date"
            value={dateTo}
            onChange={(e) => {
              setDateTo(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>

        <div className="button-group">
          {[500, 1000, 2000].map((size) => (
            <button
              key={size}
              type="button"
              className={`button ${pageSize === size ? "active" : "inactive"}`}
              onClick={() => handlePageSizeChange(size)}
            >
              {size}
            </button>
          ))}
        </div>
      </div>

      {loading && <p className="loading-text">Loading data...</p>}
      {!loading && renderTable()}

      <div className="pagination-container">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default OriginDestination;
