import React, { useState, useEffect } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "./cardType.css";

ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale);

const CardType = () => {
  const [dateFrom, setDateFrom] = useState(new Date().toISOString().split("T")[0]);
  const [dateTo, setDateTo] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null); // Initialize chartData as null

  const fetchData = async () => {
    setLoading(true);
    try {
      const payload = {
        lim: 10,
        sd: dateFrom,
        ed: dateTo,
        page: 1,
      };

      const response = await axios.post("http://192.168.60.100:4100/PDTNHB/lrt/view", payload);
      console.log("Full Response:", response.data);

      const fetchedData = response.data.data || [];
      processChartData(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const processChartData = (data) => {
    const cardTypeCount = {};

    data.forEach((item) => {
      if (item.card_type_var) {
        cardTypeCount[item.card_type_var] = (cardTypeCount[item.card_type_var] || 0) + 1;
      }
    });

    const labels = Object.keys(cardTypeCount);
    const values = Object.values(cardTypeCount);

    // Set a fixed color palette with slight variations of rgba(250, 180, 70, 0.4)
    const backgroundColors = values.map((_, index) => {
      // Vary the alpha value slightly for each segment
      const alpha = 0.4 + (index * 0.1); // Slightly increase alpha for each segment
      return `rgba(250, 180, 70, ${alpha})`;
    });

    setChartData({
      labels,
      datasets: [
        {
          label: "Card Type Distribution",
          data: values,
          backgroundColor: backgroundColors,
          borderWidth: 1,
          borderColor: "rgba(255, 255, 255, 0.8)",
        },
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, [dateFrom, dateTo]);

  return (
    <div className="chart-container">
      <div className="chart-title">Jumlah Transaksi Berdasarkan Tipe Kartu</div>
      <div className="filter-container">
        <div className="date-filters">
          <label htmlFor="dateFrom" style={{ color: "white" }}>Dari:</label>
          <input
            id="dateFrom"
            type="date"
            value={dateFrom}
            onChange={(e) => setDateFrom(e.target.value)}
            style={{ marginRight: "10px" }}
          />

          <label htmlFor="dateTo" style={{ color: "white" }}>Sampai:</label>
          <input
            id="dateTo"
            type="date"
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
          />
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        chartData && (
          <Doughnut
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "left",
                },
                title: {
                  display: true,
                  text: "Distribution of Card Types",
                },
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    },
                  },
                },
              },
              cutout: "50%",
            }}
            width={500}
            height={500}
          />
        )
      )}
    </div>
  );
};

export default CardType;
