import React, { useEffect, useState } from "react";
import axios from "axios";
import "./dataLRT.css";

const DataLRT: React.FC = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [dateFrom, setDateFrom] = useState(new Date().toISOString().split("T")[0]);
  const [dateTo, setDateTo] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const payload = { lim: pageSize, sd: dateFrom, ed: dateTo, page: currentPage };
      const { data: responseData } = await axios.post("http://192.168.60.100:4100/PDTNHB/lrt/view", payload);

      setData(responseData.data || []);
      setTotalPages(responseData.infromation?.TotalPages || 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDateTime = (isoString: string | null): string => {
    if (!isoString) return "-";
    const date = new Date(isoString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, dateFrom, dateTo]);

  return (
    <div className="dataLRT-container">
      {/* Filters */}
      <div className="dataLRT-filtersContainer">
        <div className="dataLRT-dateFilters">
          <label htmlFor="dateFrom">Dari:</label>
          <input
            id="dateFrom"
            type="date"
            value={dateFrom}
            onChange={(e) => {
              setDateFrom(e.target.value);
              setCurrentPage(1);
            }}
          />
          <label htmlFor="dateTo">Sampai:</label>
          <input
            id="dateTo"
            type="date"
            value={dateTo}
            onChange={(e) => {
              setDateTo(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>

        <div className="dataLRT-buttonGroup">
          {[500, 1000, 2000].map((size) => (
            <button
              key={size}
              className={`dataLRT-button ${pageSize === size ? "dataLRT-buttonActive" : ""}`}
              onClick={() => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            >
              {size}
            </button>
          ))}
        </div>
      </div>

      {/* Loading State */}
      {loading ? (
        <p className="dataLRT-loadingText">Loading data...</p>
      ) : (
        <>
          {/* Table */}
          <table className="dataLRT-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Card Number</th>
                <th>Terminal In</th>
                <th>Terminal Out</th>
                <th>Gate In</th>
                <th>Gate Out</th>
                <th>Card Type</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{(currentPage - 1) * pageSize + index + 1}</td>
                    <td>{item.card_number_var || "-"}</td>
                    <td>{item.terminal_in || "-"}</td>
                    <td>{item.terminal_out || "-"}</td>
                    <td>{formatDateTime(item.gate_in_on_dtm)}</td>
                    <td>{formatDateTime(item.gate_out_on_dtm)}</td>
                    <td>{item.card_type_var || "-"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="dataLRT-noData">Tidak ada data</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="dataLRT-paginationContainer">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DataLRT;
