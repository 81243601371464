import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";


function LalinDalkot(props: any) {
  // const [token, setToken] = useState('');
  const JmlKendaraan = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/7651daeb-be31-423b-92a3-2660b6253606/page/CEZiE"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    )

  };

  return (
    <div>
      <ContentHeader title="Volume Lalu Lintas Dalam Kota" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Volume Lalu Lintas Dalam Kota</h3>
        </div>
        <div className="card-body">
          <JmlKendaraan />
        </div>
      </div>
    </div>
  );
}

export default LalinDalkot;
