import { useEffect, useState } from "react";
import axios from "axios";

const TOKEN_KEY = "token"; // Kunci penyimpanan token di localStorage
const TOKEN_URL = "https://portaldata.jaktraffic.my.id/APIS/User/hitTk3N"; // URL untuk mendapatkan token

export const useToken = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem(TOKEN_KEY));

  // Fungsi untuk mengambil token baru dari API
  const fetchToken = async () => {
    try {
      const response = await axios.get(TOKEN_URL);
      const newToken = response.data.token;

      if (newToken) {
        localStorage.setItem(TOKEN_KEY, newToken);
        setToken(newToken);
      }
    } catch (error) {
      console.error("Gagal mendapatkan token:", error);
    }
  };

  // Fungsi untuk memperbarui token secara manual
  const refreshToken = async () => {
    console.log("Refreshing token...");
    await fetchToken();
  };

  // Ambil token saat pertama kali aplikasi dibuka
  useEffect(() => {
    if (!token) {
      fetchToken();
    }

    // Set interval untuk refresh token setiap 15 menit
    const interval = setInterval(refreshToken, 900000);
    return () => clearInterval(interval);
  }, []);

  return { token, refreshToken };
};
