import React, { useEffect, useState } from "react";
import axios from "axios";
import DataLRT from "@app/components/lrt/dataLRT/dataLRT";
import OriginDestination from "@app/components/lrt/originDestination/originDestination";
import StationChart from "@app/components/lrt/stationChart/stationChart";
import CardType from "@app/components/lrt/cardType/cardType";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register the required components globally
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale
);

function Lrt() {
  return (
    <div style={{ padding: "0 20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "40px", fontSize: "28px", fontWeight: "600", color: "#4A90E2" }}>
        Data LRT
      </h2>

      {/* Card for both charts with two-column layout */}
      <div
        className="chart-card"
        style={{
          width: "100%",
          // background: "linear-gradient(180deg,rgb(122, 116, 107) 0%,rgb(199, 1, 17) 100%);", // Gradient background
          borderRadius: "20px",
          padding: "30px",
          boxShadow: "0 12px 30px rgba(0, 0, 0, 0.15)", // Deep shadow for depth
          transition: "transform 0.3s, box-shadow 0.3s",
        }}
      >
       

        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "25px",
          }}
        >
          {/* Chart Station - Left Column */}
          <div style={{
            flex: 1, maxWidth: "65%", maxHeight: "500px", display: "flex", justifyContent: "center",
            borderRadius: "15px", overflow: "hidden", background: "#fff", boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)"
          }}>
            <StationChart />
          </div>

          {/* Chart Payment - Right Column */}
          <div style={{
            flex: 1, maxWidth: "30%", maxHeight: "500px", display: "flex", justifyContent: "center",
            borderRadius: "15px", overflow: "hidden", background: "#fff", boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)"
          }}>
            <CardType />
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div
        className="table-card"
        style={{
          width: "100%",
           // Gradient background for table card
          borderRadius: "20px",
          padding: "30px",
          boxShadow: "0 12px 30px rgba(0, 0, 0, 0.15)",
          marginTop: "50px",
          transition: "transform 0.3s, box-shadow 0.3s",
        }}
      >
        
        <OriginDestination />
      </div>
      <div
        className="table-card"
        style={{
          width: "100%",
           // Gradient background for table card
          borderRadius: "20px",
          padding: "30px",
          boxShadow: "0 12px 30px rgba(0, 0, 0, 0.15)",
          marginTop: "50px",
          transition: "transform 0.3s, box-shadow 0.3s",
        }}
      >
        
        <DataLRT />
      </div>
    </div>
  );
}

export default Lrt;
