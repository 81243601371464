import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "./paymentmethodMRT.css";
import { useToken } from "@app/hooks/useToken";

// Register Chart.js components globally
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale
);

const PaymentMethodMRT = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Payment Media",
        data: [],
        backgroundColor: [
          "rgba(0, 123, 255, 0.6)", // Blue
          "rgba(40, 167, 69, 0.6)", // Green
          "rgba(0, 255, 255, 0.6)", // Light Cyan
          "rgba(0, 128, 0, 0.6)", // Dark Green
          "rgba(70, 130, 180, 0.6)", // Steel Blue
          "rgba(34, 193, 195, 0.6)", // Aqua
        ],
        borderWidth: 1,
        borderColor: "rgba(255, 255, 255, 0.8)",
      },
    ],
  });
  const { token, refreshToken } = useToken();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    refreshToken();
    const interval = setInterval(refreshToken, 900000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = useCallback(async () => {
    if (!token) return;

    try {
      const response = await axios.get("http://192.168.60.100:4100/pqs/mrtj/view", {
        params: { token, lim: 50000, dt: date, pg: 1 },
      });

      const data = response.data?.Data || [];
      processChartData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [token, date]);

  const processChartData = (data) => {
    const paymentCount = {};

    // Count occurrences for payment media
    data.forEach((item) => {
      if (item.metode_pembayaran) {
        paymentCount[item.metode_pembayaran] =
          (paymentCount[item.metode_pembayaran] || 0) + 1;
      }
    });

    const labels = Object.keys(paymentCount);
    const values = Object.values(paymentCount);

    setChartData({
      labels, 
      datasets: [
        {
          label: "Payment Media",
          data: values,
          backgroundColor: [
            "rgba(0, 123, 255, 0.6)", // Blue
            "rgba(40, 167, 69, 0.6)", // Green
            "rgba(0, 255, 255, 0.6)", // Light Cyan
            "rgba(0, 128, 0, 0.6)", // Dark Green
            "rgba(70, 130, 180, 0.6)", // Steel Blue
            "rgba(34, 193, 195, 0.6)", // Aqua
          ],
          borderWidth: 1,
          borderColor: "rgba(255, 255, 255, 0.8)",
        },
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="payment-method-chart-container">
      <div className="payment-method-chart-title">
        Jumlah Transaksi Berdasarkan Metode Pembayaran
      </div>
      <div className="payment-method-filter-container">
        <input
          id="datePicker"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="payment-method-date-picker"
        />
      </div>

      <Doughnut
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Distribution of Payment Media",
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return `${tooltipItem.label}: ${tooltipItem.raw}`;
                },
              },
            },
          },
          cutout: "50%", // Adjusted cutout for better appearance
        }}
      />
    </div>
  );
};

export default PaymentMethodMRT;
