import React, { useEffect, useState } from "react";
import axios from "axios";
import "./dataMRT.css";
import { useToken } from "@app/hooks/useToken";

function DataMRT() {
  const { token, refreshToken } = useToken();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    refreshToken(); // Refresh token every 15 minutes
    const interval = setInterval(refreshToken, 900000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `http://192.168.60.100:4100/pqs/mrtj/view`,
        {
          params: {
            token: token,
            lim: pageSize,
            dt: date,
            pg: currentPage,
          },
        }
      );
      console.log(response.data.Data);
      setData(response.data.Data || []);
      const totalPagesResponse = response.data.page.split(" / ")[1];
      setTotalPages(parseInt(totalPagesResponse, 10));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken();
        fetchData();
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, date, token]);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      await refreshToken();
    }
  };

  return (
    <div>
      {/* Filters */}
      <div className="filters-container">
        {/* Date Filter */}
        <input
          type="date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
            setCurrentPage(1);
          }}
        />

        {/* Page Size Filter */}
        <div className="button-group">
          {[500, 1000, 2000].map((size) => (
            <button
              key={size}
              type="button"
              className={`button ${pageSize === size ? "active" : "inactive"}`}
              onClick={() => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            >
              {size}
            </button>
          ))}
        </div>
      </div>

      {/* Table */}
      <table className="table">
        <thead className="table-header">
          <tr>
            <th>No</th>
            <th>Tanggal</th>
            <th>Origin</th>
            <th>Destination</th>
            <th>Destination Timestamp</th>
            <th>Metode Pembayaran</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{(currentPage - 1) * pageSize + index + 1}</td>
              <td>{item.tanggal}</td>
              <td>{item.origin}</td>
              <td>{item.destination}</td>
              <td>{item.destination_timestamp || "N/A"}</td>
              <td>{item.metode_pembayaran || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination-container">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default DataMRT;
