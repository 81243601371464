import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import "./stationChart.css"

ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement);

const StationChart = () => {
  const [destinationData, setDestinationData] = useState<Record<string, number>>({});
  const [stationLabels, setStationLabels] = useState<Record<string, string>>({});
  const [dateFrom, setDateFrom] = useState(new Date().toISOString().split("T")[0]);
  const [dateTo, setDateTo] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);

  const chartRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const payload = {
        lim: 10000,
        sd: dateFrom,
        ed: dateTo,
        page: 1,
      };

      const response = await axios.post("http://192.168.60.100:4100/PDTNHB/lrt/view", payload);
      console.log("Full Response:", response.data);

      const fetchedData = response.data.data || [];
      processChartData(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateFrom, dateTo]);

  const processChartData = (data) => {
    const stationCount = {};
    const stationLabels = {};

    data.forEach((item) => {
      if (item.station_code_var) {
        const stationCode = item.station_code_var;
        stationCount[stationCode] = (stationCount[stationCode] || 0) + 1;
        stationLabels[stationCode] = item.station_code_var;
      }
    });

    setDestinationData(stationCount);
    setStationLabels(stationLabels);
  };

  const getGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#be7c1f");
    gradient.addColorStop(1, "#e60113");
    return gradient;
  };

  const chartData = {
    labels: Object.keys(destinationData),
    datasets: [
      {
        label: "Destinations",
        data: Object.values(destinationData),
        backgroundColor: chartRef.current ? getGradient(chartRef.current.ctx) : "#2196f3",
        borderRadius: 10,
        hoverBackgroundColor: "#e60113",
      },
    ],
  };

  return (
    <div className="chart-container">
      <div className="filter-container">
        <label htmlFor="dateFrom" style={{ color: "white" }}>Dari:</label>
        <input
          id="dateFrom"
          type="date"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
          style={{ marginRight: "10px" }}
        />

        <label htmlFor="dateTo" style={{ color: "white" }}>Sampai:</label>
        <input
          id="dateTo"
          type="date"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
        />
      </div>

      <div className="chart-wrapper">
        <h2 className="chart-title">Jumlah Penumpang Berdasarkan Stasiun</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Bar
            ref={chartRef}
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: { position: "bottom" },
                title: { display: true, text: "Destinasi Penumpang MRT" },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const label = context.label;
                      return stationLabels[label]
                        ? `${stationLabels[label]}: ${context.raw}`
                        : `${label}: ${context.raw}`;
                    },
                  },
                },
              },
              scales: {
                x: { ticks: { color: "#6e1e25", font: { size: 14, weight: "bold" } } },
                y: { ticks: { color: "#6e1e25", font: { size: 14, weight: "bold" } } },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default StationChart;
