import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import "./destinationMRT.css";
import { useToken } from "@app/hooks/useToken";

ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement);

const DestinationMRT: React.FC = () => {
  const [destinationData, setDestinationData] = useState<Record<string, number>>({});
  const [fullLabels, setFullLabels] = useState<Record<string, string>>({});
  const { token, refreshToken } = useToken();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  
  const chartRef = useRef(null);

  useEffect(() => {
    refreshToken();
    const interval = setInterval(refreshToken, 900000); // Refresh token setiap 15 menit
    return () => clearInterval(interval);
  }, []);

  const fetchData = useCallback(async () => {
    if (!token) return;

    try {
      const response = await axios.get("http://192.168.60.100:4100/pqs/mrtj/view", {
        params: { token, lim: 50000, dt: date, pg: 1 },
      });

      const data = response.data?.Data || [];
      processChartData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [token, date]);

  useEffect(() => {
    const refreshAndFetch = async () => {
      await refreshToken();
      fetchData();
    };
    refreshAndFetch();
  }, [date, fetchData]);

  const processChartData = (data: Array<any>) => {
    const stationCount: Record<string, number> = {};
    const labels: Record<string, string> = {};

    data.forEach((item) => {
      if (item.destination) {
        const fullLabel = item.destination;
        stationCount[fullLabel] = (stationCount[fullLabel] || 0) + 1;
        labels[fullLabel] = fullLabel;
      }
    });

    setDestinationData(stationCount);
    setFullLabels(labels);
  };

  const getGradient = (ctx: CanvasRenderingContext2D) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#2196f3");
    gradient.addColorStop(1, "#4caf50");
    return gradient;
  };

  const chartData = {
    labels: Object.keys(destinationData),
    datasets: [
      {
        label: "Destinations",
        data: Object.values(destinationData),
        backgroundColor: chartRef.current ? getGradient(chartRef.current.ctx) : "#2196f3",
        borderRadius: 10,
        hoverBackgroundColor: "#4caf50",
      },
    ],
  };

  return (
    <div className="destination-mrt-container">
      <div className="destination-mrt__filter">
        <label htmlFor="datePicker" className="destination-mrt__filter-label">Pilih Tanggal:</label>
        <input
          id="datePicker"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="destination-mrt__date-picker"
        />
      </div>

      <div className="destination-mrt__chart">
        <h2 className="destination-mrt__chart-title">Jumlah Penumpang Berdasarkan Destinasi</h2>
        <Bar
          ref={chartRef}
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "bottom" },
              title: { display: true, text: "Destinasi Penumpang MRT" },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const label = context.label || "";
                    return fullLabels[label]
                      ? `${fullLabels[label]}: ${context.raw}`
                      : `${label}: ${context.raw}`;
                  },
                },
              },
            },
            scales: {
              x: { ticks: { color: "#004d40", font: { size: 14, weight: "bold" } } },
              y: { ticks: { color: "#004d40", font: { size: 14, weight: "bold" } } },
            },
          }}
        />
      </div>
    </div>
  );
};

export default DestinationMRT;
